footer{
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 28rem;
}

footer a{
    color: var(--color-bg);
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.footer_socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
    font-size: 35px;

}

.footer_socials a, .footer_socials .copy_email{
    background: var(--color-bg);
    color:var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    height: 35px;
    border: 1px solid transparent;
}


.footer_socials a:hover, .footer_socials .copy_email:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}





.footer_copyright{
    margin-bottom: 4rem;
    color: var(--color-bg);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

footer h3 {
    color: var(--color-bg);
}


.btn_cv{
    width: max-content;
    display: inline-block;
    color: var(--color-bg);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-bg);
    transition: var(--transition);
    
}

.btn_cv:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.resume-section p {
    color: var(--color-bg);
}

/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
   footer{
        margin-top:70rem;
    }
    
}


/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {
     .permalinksr{
        flex-direction: column;
        gap: 1.5rem;
    }

    footer{
        margin-top:60rem;
    }
    
    
}