.portfolio_container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2.5rem;
    margin-bottom: 100px;
}

.portfolio_item{
    background: white; 
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item-image img{
    width: 395px;
    height: 200px;
    object-fit: caontain;
    margin: 0 auto;
    border-radius: 1.5rem;
    margin-bottom: 1rem;
}

/* .portfolio_item h3{
    margin:1.2rem 0 2rem;

} */

.portfolio_item h5{
    margin-bottom: 1rem;

}

.portfolio_item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

#portfolio {
    margin-top: 8rem;
    height: 120vh;
}

/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/

@media screen and (max-width: 1024px) {
    .portfolio_container{
        grid-template-columns: 1fr;
        gap:1.2rem;
    }
    #portfolio{
           height: 90vh;
    }
}


/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {
     .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 0 auto;
       ;
    }
    .portfolio_item{
        width: 69%;
    }

    #portfolio{
        height: 210vh;
       
    }
  
}
