.skills_container{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills_container > div {
    background: white;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.skills_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.skills_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}


.skills_content{
    display: grid;
    grid-template-columns: 1fr;
    row-gap:2rem;
}

.skills_frontend_icons{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:0.5rem;
    margin-top: 2rem;
}

.skills_details{
    display: flex;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
}

.skills_tolearn_container {
    text-align: center;
    color: var(--color-primary);
    justify-content: center;
    align-items: center;
    margin: 0 auto; 

}

.skills_tolearn_container > div {
    background: white;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 40%;
    margin-top:50px; 
    grid-column-start: 2;
    grid-column-end: five;
    grid-row-start: row1-start;
    grid-row-end: 3;
    margin: 50px auto;
}

.skills_tolearn_container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.skills_tolearn_container > div h3 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

#skills {
    margin-top: 8rem;
    height: 100vh;
}


/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
    .skills_container{
        grid-template-columns: 1fr;
    }
    .skills_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto; 
    }
    .skills_content{
        padding: 1rem;
    }

    .skills_tolearn_container{
        width: var(--container-width-md);
    }

    .skills_tolearn_container>div{
        grid-template-columns: 1fr;
        display: grid;
        width: 80%;
        padding: 2rem;
        margin: 0 auto; 
        margin-top: 80px;
       
    }

    #skills{
        height: 120vh;
    }
    
}
/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {
    .skills_container{
        margin-top: 8rem;
        height: 40%
    }

     .experience_container{
        gap: 1rem;
        /* margin-top: 30rem; */
    }
    
    .skills_content{
        width: 110%;
        padding-left: 0;
    }

     .skills_frontend_icons{
        margin: 0 auto;
     }

     #skills{
        height: 150vh;
     }

      .skills_tolearn_container>div{
        
        margin-top: 275px;
       
    }
   

    
     
}