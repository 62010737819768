.experience_container{
    display: grid;
    grid-template-columns:repeat(2, 1fr) ;
    gap: 3rem;
}

.experience{
    background: var(--color-bg-variant);
    border-radius:  2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.experience:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience_head{
    background: var(--color-primary);
    padding: 1.2rem;
    border-radius: 2rem 2rem  0 0 ;
    box-shadow: 0 2rem 1 rem rgba(0, 0, 0,0.1);
    
}

/* .experience h5{
    margin-top: 0;
} */

.experience_head h3{
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
    margin-top: 2px;
}

.experience-image{
    width: 300px;
    height: 200px;
    object-fit: cover;
    margin: 0 auto;
    margin-top: 1rem;
}

.experience p {
    font-size: 0.9rem;
    color: var(--color-primary);
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    padding: 0;
}

.experience_text{
    margin:30px;
}

#experience {
    margin-top: 8rem;
    height: 100vh;
}

/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/

@media screen and (max-width: 1024px) {
    .experience_container{
        grid-template-columns: 1fr;
        gap:2rem;
    }
    #experience {
        height: 100vh;
    }
}

/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {
     .experience_container{
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 0 auto;
        
    }

    #experience{
        height: 200vh;
       
    }
    
}
