nav {
    background: rgba(126, 126, 126, 19%);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    right: 0em;
    transform: translateX(-50%);
    bottom: 15rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover{
    background: rgba(42, 42, 42, 0.3);
}

nav a.active {
    background: var(--color-primary);
    color:var(--color-bg);
}

.tooltip {
  position: relative;
  display: inline-block;

}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
   
  nav{
    margin-bottom: 15rem;
    margin-right: -0.5rem;
    padding: 5px;
  }

    
}


/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */

@media screen and (max-width: 600px) {
  nav{
    display: none;
  }
}