@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

* {
    margin: 0 ;
    padding: 0;
    border: 0;
    box-sizing: 0;
    list-style: none;
    text-decoration: none;

}

/* CSS Variables */
:root{
    --color-bg: #F5F5F5;
    --color-bg-variant: #F5EDE4;
    --color-primary: #EF5C8E;
    -color-primary-variant: Orgba(77, 181, 255, 0.4) ;
    --color-white:#F49E58;
    --color-light: #D58405;

    --transition: all 400ms ease; 

    /* Width of the container class */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body{
    font-family: 'Montserrat', sans-serif;
    background: var(--color-bg-variant);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/); */

}

/* +++++++++++++ GENERAL STYLES++++++++++++++++++ */
.container{
    width: var(--container-width-lg);
    margin: 0 auto; 
}

h1,h2,h3,h4,h5{
    font-weight: 500;
}

h1{
    font-size: 4rem;
}

/* section  {
    margin-top: 8rem;
    height: 120vh;
} */

section > h1, section > h3{

    text-align: center;
    color: var(--color-light);

}

section > h2{
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    
}

.btn:hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background: var(--color-primary);
    color: var(--color-bg);

}

img{
    display: block;
    width: 100%;
    object-fit: cover;
}

p{
    margin-bottom: 0;
    
}
 
/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md)
    }
    section{
        margin-top: 6rem ;
    }
}

/*+++++++++++++ Media queries(e.g. mobile phones)+++++++++++++++++++*/
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm)
    }
    section > h2 {
        margin-bottom: 2rem ;
    }

    h1{
        font-size: 40px;
        text-align: center;
        margin: 0 auto;
    }
}