.about_container{
    display: grid;
    grid-template-columns: 35% 60%;
    gap: 5%;
    
}

.about_me{
    width: 90%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent,
        var(--color-primary),
        transparent
        );
    display: grid;
    place-items: center;
}

.about_me img{
    height:450px;
    width: 360px;
}

.about h3{
    text-align: center;
}
.about_me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about_me-image:hover{
    transform: rotate(0);
}

.about_cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:1.5rem;

}

.about_card{
    background: var(--color-bg-variant) ;
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about_card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about_card h3{
    font-size: 0.95rem;
}

.about_card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about_content p {
    margin: 2rem 0 .1rem;
    color: var(--color-light);
}

.about_card img {
    height: 300px;
}

/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
    .about_container{
        /* background-color: red; */
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about_me{
        width: 50%;
        margin: 2rem;
    }
    .about_content p{
        margin: 1rem 0 1.5rem ;
    }
    
    header{
        height: 55vh;
    }

     section {
         height: 100vh;
    }

     .about_me {
        margin: 0 auto;
        width: 40%;
        
    }

     .about_me img{
        margin: 0 auto;
        height:300px;
        width: 290px;
        border-radius: 2rem;
    }
}

/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {

    .about{
        margin-bottom: 5rem;
    }
    
    .about_me img{
        height:250px;
        width: 190px;
    }

     .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    
    }
    .about_cards{
        grid-template-columns: 1fr;
        gap:1rem;
    }
    .about_content{
        text-align: center;
    }
    
    .about_content p {
        margin: 1rem 1rem;
        font-size: 11px;
        
        
    }

    .about_me img{
    margin-top:40px ;
    }

    #about{
        height: 180vh;
    }
    
    #about{
        height: 220vh;
    }
   
}