header{
    height: 100vh;
    padding-top: 2rem;
    overflow: hidden;
    background-image: url(/src/assets/background.jpg);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    background-position: center ;
}

header h2{
  margin-top: 8rem;
}

.text-light {
  margin-top: 0px; 
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*+++++++++++ CTA +++++++++++++++++*/

.cta {
    margin-top: 2.5rem;
    flex-direction: flex;
    gap:2rem;
    justify-content: center;
    
}

/*+++++++++++ Header Social +++++++++++++++++*/


.header_socials{
    display: flex;
    flex-direction:column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left:0;
    bottom: 20rem;
    font-size: 35px;
    cursor: copy;
    color: var(--color-primary);
}

.header_socials :hover{
     color: var(--color-white);
   
}

.icon-size{
    font-size: 72px;
}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*+++++++++++ header animation +++++++++++++++++*/
.text-light {
    display: inline-block;
}


.dropping-texts {
  display: inline-block;
  width: 185px;
  text-align: left;
  height: 30px;
  vertical-align: -2px;
  margin: 0 auto;
  padding-left: 8px;
  color: var(--color-light);
  font-size: 1.5em;
}

.dropping-texts > div {
  
  opacity:0;
  margin-left:20px;
  position:absolute;
  font-weight:500;   
  box-shadow: 0px 60px 25px -20px rgba(0,0,0,0.5);
}

.dropping-texts > div:nth-child(1) {
  animation: roll 5s linear infinite 0s;
}
.dropping-texts > div:nth-child(2) {
  animation: roll 5s linear infinite 1s;
}
.dropping-texts > div:nth-child(3) {
  animation: roll 5s linear infinite 2s;
}
.dropping-texts > div:nth-child(4) {
  animation: roll2 5s linear infinite 3s;
}

@keyframes roll {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  20% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  27% {
    font-size:0px;
    opacity:0.5;
    margin-left:20px;
    margin-top:100px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes roll2 {
  0% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(-25deg);
  }
  3% {
    opacity:1;
    transform: rotate(0deg);
  }
  5% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
  }
  30% {
    font-size:inherit;
    opacity:1;
    margin-left:0px;
    margin-top:0px;
    transform: rotate(0deg);
  }
  37% {
    font-size:1500px;
    opacity:0;
    margin-left:-1000px;
    margin-top:-800px;
  }
  100% {
    font-size:0px;
    opacity:0;
    margin-left:-30px;
    margin-top:0px;
    transform: rotate(15deg);
  }
}

@keyframes bg {
  0% {background: #ff0075;}
  3% {background: #0094ff;}
  20% {background: #0094ff;}
  23% {background: #b200ff;}
  40% {background: #b200ff;}
  43% {background: #8BC34A;}
  60% {background: #8BC34A;}
  63% {background: #F44336;}
  80% {background: #F44336;}
  83% {background: #F44336;}
  100% {background: #F44336;}
}






/*+++++++++++ profile image +++++++++++++++++*/
.me{
    background: linear-gradient(var(--color-primary),transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius:12rem 12rem 0 0  ;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/*+++++++++++ scroll down  +++++++++++++++++*/
.scroll_down{
    position: absolute;
    right: -5.3rem;
    bottom: 26rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
    cursor: default;

}

.tooltip_CTA {
  position: relative;
  display: inline-block;
  margin: 6px;
}

.tooltip_CTA .tooltiptext_CTA {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 50%;
  margin-left: -60px;
}

.tooltip_CTA:hover .tooltiptext_CTA {
  visibility: visible;
}





/*+++++++++++++ Media queries(e.g. tablets)+++++++++++++++++++*/
@media screen and (max-width: 1024px) {
    .container{
        /* background-color: red; */
        height: 68vh;
    }

    .cta .btn{
      background: var(--color-bg);
    }

    .text-light{
      margin-top: 0rem;
      margin-left: 0.1rem;
    }

    header h2{
    margin-top: 4rem;
    }

    .scroll_down{
      margin-right: 3rem;
      
    }
    
}

/* +++++++++++++ Media queries(e.g. mobiles)+++++++++++++++++++ */
@media screen and (max-width: 600px) {
  
  header{
   
    background-size: cover;
    background-repeat: no-repeat;
  }

  header h2{
  margin-top: 2rem;
  }

  .text-light{
    margin-top: 0rem;
    margin-left: 0.1rem;
  }

  .container{
        /* background-color:yellow; */
       height: 100vh;
    }
    .header_socials, .scroll_down{
        display: none;
    }
    .dropping-texts{
      width: 80%;
    }

    .cta .btn{
      background: var(--color-bg);
    }

}
